import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "デザイナーがデザインツールを使わずに、React を使ってデザインした話",
  "date": "2020-06-19T10:45:58.000Z",
  "slug": "entry/2020/06/19/194558",
  "tags": ["medley"],
  "hero": "./2020_06_19.png",
  "heroAlt": "React でデザイン"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`メドレーのデザイナー酒井です。最近、`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`JobMedley`}</a>{`から`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`に異動しました。
自分はデザインはもちろん、HTML/CSS/JS 実装してプルリク送ったりしているちょっとフロントエンド実装領域に軸足が寄ったタイプのデザイナーです。`}</p>
    <p>{`ここでは以前所属していた JobMedley 事業部の話をさせていただきます。`}</p>
    <p>{`当時、JobMedley の社内システムのリニューアルプロジェクトにデザイナーとして参加していました。通常、デザイナーがデザインをするときには Skecth や Figma のようなデザインツールを利用するのが一般的かと思います。`}</p>
    <p>{`弊社でも基本的にはデザインツールでデザインを行うことが多いのですが、プロジェクトによっては、よりリアルなモックアップが必要なため、デザイナー自身がコーディングでデザインを行い、ブラッシュアップしていくことがあります。その後、フロントエンド実装者が、デザイナーが作ったデザインを参考に、しっかりと設計されたもので作り直します。`}</p>
    <p>{`今回のリニューアルプロジェクトのデザイン・モックアップの制作では、Figma や Sketch などのデザインツールは使用せず、React でコーディング行い、デザインを制作しています。ここが特殊な制作フローになると思うので、このエントリでは React をデザインツールとして使ったときの流れとメリット・デメリット、ちょっとニッチなポイントに絞ってお話していきます。`}</p>
    <h1>{`デザインの流れ`}</h1>
    <h2>{`1.まずは React を勉強する`}</h2>
    <p>{`元々受託制作会社でデザイナーをやっていた時代に Vue.js での開発経験はありましたが、React は今回が初めてでした。そのため、一旦公式チュートリアルを一通り読み、Google などで調べつつ、基礎知識のインプットすることからはじめました。`}</p>
    <h2>{`2.ワイヤーフレームを作る`}</h2>
    <p>{`さすがにワイヤーフレーム無しでコード実装には入れないので、ここでは Figma などのツールを使います。ワイヤーフレームを作り、要件定義を進めていきますが、デザインに関してもある程度のあたりを付けていきます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200619/20200619172937.png",
      "alt": "20200619172937.png"
    }}></img>
    <h2>{`3.開発環境構築`}</h2>
    <p>{`今回は Next.js で制作を行っています。
そもそも、プロダクトの実装で Next.js を使用することが決まっていたので、デザイン側も Next.js を採用しましたが、結果的に良かったなと感じています。`}</p>
    <p>{`Next.js で作っておけば、めんどくさい router の設定やデザイナーが苦手な webpack の config を記述しなくてすみます。`}</p>
    <p>{`また、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`next build && next export`}</code>{`で静的な HTML ファイルとして書き出されるため、サーバがあればすぐに共有が可能です。今回はエンジニアさんに、develop ブランチに merge されると、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`next build && next export`}</code>{`が走り、自動で Amazon S3 に静的ファイルをデプロイされるようにしてもらいました。`}</p>
    <p>{`この時点でプロダクト側のフロントエンド設計も進んでいたため、ESLint やコンポーネント設計、ライブラリなどを流用し、デザインプロジェクト用に最適化させます。`}</p>
    <h2>{`4.Next.js の設計`}</h2>
    <p>{`実際にエンジニアがプロダクトを実装する際には色々なことを検討する必要があると思いますが、デザインツールとして使用する際には、逆にそこまでガチガチにしてしまうと実装に工数がかかりすぎてしまうので「割り切り」が必要になります。私が実装した際の環境は以下のようなものです。`}</p>
    <ul>
      <li parentName="ul">{`TypeScript は入れない。要件が固まりきっていない状態で型定義をやり始めると修正に時間がかかりすぎるため。`}</li>
      <li parentName="ul">{`非同期通信処理も入れない。データがほしければローカルに json ファイルを配置しておく。`}</li>
      <li parentName="ul">{`デザイン側のソースコードの汚さはあまり気にしない。プロダクト版を作成するときにフロントエンドエンジニアがきれいに作り直してくれる。`}</li>
      <li parentName="ul">{`コンポーネント設計を意識する。共通コンポーネントとして使用する汎用的なものは先に洗い出しておく。`}</li>
      <li parentName="ul">{`テストコードは書かない。そこまでページ数が多くない上、上記のような割り切りをしているので複雑性も極端に上がらない。`}</li>
      <li parentName="ul">{`Google Chrome 最新版のみ対応とする。他のブラウザは無視する。`}</li>
    </ul>
    <p>{`（昨今のフロントエンド開発の流れと真逆、、、）`}</p>
    <h2>{`5.デザインルール設計`}</h2>
    <p>{`続いてデザインルールの設計を行います。`}</p>
    <p>{`theme.js ファイルを作成し、以下を定義します。この時点で定義が完全にできていることは無いと思うので、分かる範囲で書いていきます。最終的に、不要なものを消したり、一緒にできるものは統合して定義を減らしていきます。`}</p>
    <p>{`各コンポーネントで padding や margin の余白を数値で指定したり、色味のカラーコドをベタ打ちせず、必ず theme ファイルから値を引っ張ってくるようにします。そうすると、修正が容易になる上、あとからどこで何が使われているかを把握しやすくなります。`}</p>
    <p>{`■theme 内に記述する内容のイメージ`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`fontFamily// fontFamily を定義
colors// カラーコードの一覧
spaces // 4/8/16 など余白に使う数字を定義。
fontSizes // fontSize を定義
lineHeight // lineHeight を定義
fontWeight // fontWeight を定義。
boxShadow // ドロップシャドウを使用するなら定義`}</code></pre></div>
    <h2>{`6.コンポーネント設計`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200619/20200619173007.png",
      "alt": "20200619173007.png"
    }}></img>
    <p>{`続いて、コンポーネントとコンポーネントに必要な props を洗い出します。`}</p>
    <p>{`世の中には優秀な UI フレームワークが複数存在していますのでそういったものを参考にします。そういったものはほとんど同じようなコンポーネント設計になっているのでコンポーネントの分け方や受け取る props などを参考にすると作業が捗ります。`}</p>
    <ul>
      <li parentName="ul">{`Ant Design `}<a parentName="li" {...{
          "href": "https://ant.design/"
        }}>{`https://ant.design/`}</a></li>
      <li parentName="ul">{`Material-UI `}<a parentName="li" {...{
          "href": "https://material-ui.com/"
        }}>{`https://material-ui.com/`}</a></li>
      <li parentName="ul">{`Element `}<a parentName="li" {...{
          "href": "https://element.eleme.io/#/en-US"
        }}>{`https://element.eleme.io/#/en-US`}</a></li>
    </ul>
    <p>{`ここで気をつけたいのは、コンポーネントの数を極力減らすように努力することです。`}</p>
    <p>{`同一機能の別コンポーネントが複数できてしまうと、今後の機能拡張時に、どのコンポーネントを使うか迷うことになります。ほとんどのエンジニアやデザイナーにとって、コンポーネント選びで迷うことは時間の無駄です。選択肢は極力減らす努力をし、減らせないようなら使用する場所を明確に定義する必要があります。`}</p>
    <p>{`例えば`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`component/tab1/
component/tab2/
component/tab3/`}</code></pre></div>
    <p>{`のようにタブコンポーネントが複数あると、あとから見たときにどれを使えばいいかわからなくなりますよね。
これを許容してしまうと、知らぬ間に`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`component/tab4/`}</code></pre></div>
    <p>{`ができあがっていることでしょう。`}</p>
    <h2>{`7.実装`}</h2>
    <p>{`まずは大枠のページテンプレートを制作します。その後、小さいコンポーネントから順に作り上げていき、ページテンプレートに配置していきます。コンポーネント化ができていれば、見た目の変更はあとから行えるので、まずは page 内に配置し、機能することを目指します。`}</p>
    <p>{`この時点で、デザインを細部まで作り込んだとしても、結局全体を見渡してから細かく調整をかけていくと思うので、そこまでセンシティブにならずに行っていきます。`}</p>
    <h2>{`8.確認・ヒアリング・修正の繰り返し`}</h2>
    <p>{`見た目が完全にできていない状態で、確認観点を絞って、PM や、ディレクター、実際に業務で使う方々にチェックしてもらいフィードバックを受けます。
徐々にブラッシュアップしていき、完成を目指します。`}</p>
    <p>{`デザイン中には常に以下を意識して作業することで、無駄なものを介在させない設計を目指します。`}</p>
    <ul>
      <li parentName="ul">{`コンポーネントを共通化できないか`}</li>
      <li parentName="ul">{`色数を減らせないか`}</li>
      <li parentName="ul">{`fontSize や lineheight などの定義を減らせないか`}</li>
    </ul>
    <h2>{`9.各種資料の作成`}</h2>
    <p>{`作っているデザインではエラー処理などすべてを表現しきれないので、補足資料として別途スプレッドシートなどにまとめていきます。`}</p>
    <p>{`また、実際のコンポーネントと想定している props、実装時の注意事項などを記載した UI コンポーネント集ページを制作し、フロントエンドエンジニアと共有します。`}</p>
    <p>{`Storybook での共有も検討しましたが、受け渡す props の想定、エンジニアへの指示、要件の補足を記載していくだけですので、Storybook ほどの機能は不要と考え、Next.js 内にシンプルな Page を作り、そこに記述する方法を選択しました。`}</p>
    <p>{`また、デザインの設計ルールも資料化し、最終的には各種資料と、制作したコード、S3 にアップされている URL を成果物としてエンジニアに実装をしていってもらいデザインのフェーズを完了させます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200619/20200619173035.png",
      "alt": "20200619173035.png"
    }}></img>
    <h1>{`React でデザインした場合のメリット`}</h1>
    <h2>{`忠実度の高いモックアップが制作できる`}</h2>
    <p>{`プロトタイプツールは数多くあれど、実装にまさる忠実度の高さなし。
最近は Figma の AutoLayout や XD のスタックなど、便利な機能も登場してきていますし、プロトタイピングに関しても様々なアクションが設定できるので、非常に便利になってきました。ただ、それでも CSS や JS の表現力にはまだ届かない部分も多いかなと感じています。`}</p>
    <p>{`ボタンを押したとき、特定の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<select/>`}</code>{`の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<option/>`}</code>{`が選択されたとき、複数条件下でのみ表示させるもの、ウインドウサイズが小さくなったときの表示など、デザインツールで実装するにはやや面倒な箇所も、コードベースなら対応可能で、デザインファイルと実装されたページでの印象差も起こりづらいです。`}</p>
    <h2>{`実際に操作できる環境を渡すことで高い精度のフィードバックをもらえる`}</h2>
    <p>{`様々なリテラシーのユーザーが使用するサービスであるからこそ、よりリアルなプロトタイプを使用し、ミスコミュニケーションを減らせました。`}</p>
    <p>{`結果論ですが、新型コロナウィルスによって、コミュニケーションを取りにくい状況が続きましたが、挙動を忠実に再現しているので、精度の高い意見を聞けたのは良かったです。`}</p>
    <h2>{`プロダクト版のフロントエンドの実装時に、こちらの意図が伝わりやすい`}</h2>
    <p>{`fontSize や space、color を theme.js ファイルにまとめておき、そこからコンポーネント側では theme 側から読み込むようにしておけば、デザインルールが把握しやすくなります。また、CSS がすでに記述されているので、プロダクト版への移植も可能となり、「デザインファイルと実装でデザインが違う」が起こりにくくなります。`}</p>
    <h2>{`Git やエディタの恩恵をフルに受けられる`}</h2>
    <p>{`一旦別ブランチでデザイン案を作る、作ったブランチの commit から cherry-pick する、過去の log を見る、特定の commit に戻るなど、Git の恩恵をフルに受けられます。また、文字列の一括検索や置換が可能なので、色味を一気に置き換えたい、どこで使われているかを探したい場合などかなり重宝しました。`}</p>
    <h1>{`とはいえいいことばかりではなかった。。。`}</h1>
    <ul>
      <li parentName="ul">{`ちょっと要素の位置を変える、などはデザインファイルのほうが全然楽。コードベースだと CSS と HTML 構造自体を書き換えないといけない`}</li>
      <li parentName="ul">{`React を理解していないと、動かなくなったりした時に、修正で時間を余計に使ってしまう`}</li>
      <li parentName="ul">{`他のデザイナーに引き継ぎしずらい`}</li>
    </ul>
    <p>{`など、通常のデザインファイルでは起こらないようなデメリットもありました。`}</p>
    <p>{`結局の所プロジェクトによって向き不向きはあります。`}</p>
    <p>{`個人的には社内の管理システム系のように、一般的なコンポーネントを組み合わせて、色々なデータを表示する必要があるサイト制作に向いているかなと思います。`}</p>
    <p>{`逆に、特殊なコンポーネントを量産するケースや、LP のようにグラフィック要素が多くなってくるとデザインツールのほうがはかどります。`}</p>
    <p>{`プロジェクトの性格に応じて最適なデザイン方法を選んでいきたいですね。`}</p>
    <p>{`デザイナーだけどコードも書きたい方や、一緒にプルリク送ってくれるデザイナーの方いらっしゃれば、ぜひメドレーで一緒に働きましょう〜`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/designer-new.html"
      }}>{`https://www.medley.jp/jobs/designer-new.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      